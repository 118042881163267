import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        aaa: 123,
        userInfo: {}, //登录状态
    },
    mutations: {},
    actions: {},
    modules: {},
});