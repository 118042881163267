import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import MetaInfo from 'vue-meta-info'
Vue.use(ElementUI)
import SIdentify from './components/code.vue' //注意引入路径
Vue.use(SIdentify)
import * as echarts from 'echarts';
//需要挂载到Vue原型上
Vue.prototype.$echarts = echarts
Vue.prototype.$a = 999;
import 'quill/dist/quill.snow.css'
// 全局封装接口调用
import util from "./utils/util.js";
Vue.prototype.$util = util;
router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title + "-企胖胖"
	}
	next()
})
router.afterEach((to, from, next) => {
	window.scrollTo(0, 0);
})
Vue.directive('myTitle', {
	inserted: function(el, binding) {
		const {
			value
		} = binding
		// 方式1，直接给v-myTitle标签的 data-title 属性赋值
		if (el.dataset.title) {
			document.title = el.dataset.title
		}
		// 方式2，通过指令传参（{{xxx}}）
		else if (value && value.title) {
			document.title = value.title
		}
	},
	update(el, binding) {
		const {
			value
		} = binding
		if (el.dataset.title) {
			document.title = el.dataset.title
		} else if (value && value.title) {
			document.title = value.title
		}
	}
})

Vue.use(MetaInfo)
Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App),
	mounted() {
		document.dispatchEvent(new Event('render-event'))
	}
}).$mount("#app");
