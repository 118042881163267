import axios from "axios";
import router from "../router/index.js";
import { Message, Loading } from 'element-ui';
import Qs from 'qs'

//线上
const upload = 'https://g.qfpanda.com/file/upload'
const host = 'https://g.qfpanda.com'

//线下192.168.0.189
// const upload = 'http://192.168.0.189:8888/file/upload'
// const host = 'http://192.168.0.189:8888/'

// axios.defaults.baseURL = "";
// axios.defaults.withCredentials = true;

// 超时设置.
axios.defaults.baseURL = "https://g.qfpanda.com";
// axios.defaults.baseURL = "http://192.168.0.189:8888";
axios.defaults.timeout = 120000;

// 请求拦截器
let loading //定义loading变量

function startLoading() { //使用Element loading-start 方法
    loading = Loading.service({
        lock: true,
        text: '加载中……',
        background: 'rgba(0, 0, 0, 0.7)'
    })
}

function endLoading() { //使用Element loading-close 方法
    loading.close()
}
//那么 showFullScreenLoading() tryHideFullScreenLoading() 要干的事儿就是将同一时刻的请求合并。
//声明一个变量 needLoadingRequestCount，每次调用showFullScreenLoading方法 needLoadingRequestCount + 1。
//调用tryHideFullScreenLoading()方法，needLoadingRequestCount - 1。needLoadingRequestCount为 0 时，结束 loading。
let needLoadingRequestCount = 0
export function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        startLoading()
    }
    needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return
    needLoadingRequestCount--
    if (needLoadingRequestCount === 0) {
        endLoading()
    }
}

axios.interceptors.request.use(
    (config) => {
        if (config.url !== "/user/info") {
            showFullScreenLoading()
        }

        // Do something before request is sent
        if (!config.type) {
            config.headers = {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        } else {
            config.headers = {
                "Content-Type": 'application/json;charset=UTF-8'
            }
        }
        if (localStorage.getItem("token")) {
            config.headers.token = localStorage.getItem("token");
        }
        return config;
    },
    (error) => {
        tryHideFullScreenLoading()
            // Do something with request error
        return Promise.reject(error);
    }
);

// 响应拦截器
axios.interceptors.response.use(
    (response) => {
        tryHideFullScreenLoading()
            // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
            // 否则的话抛出错误
        if (response.status === 200) {
            return Promise.resolve(response);

        } else {
            console.log(response.message);
            // this.$message.error(response.message);
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是2开头的的情况
    // 这里可以跟你们的后台开发人员协商好统一的错误状态码
    // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
    // 下面列举几个常见的操作，其他需求可自行扩展
    (error) => {
        tryHideFullScreenLoading()
        if (error.response && error.response.status) {
            switch (error.response.status) {
                // 401: 未登录
                // 未登录则跳转登录页面，并携带当前页面的路径
                // 在登录成功后返回当前页面，这一步需要在登录页操作。
                case 401:
                    router.replace({
                        path: "/login",
                        query: {
                            redirect: router.currentRoute.fullPath,
                        },
                    });
                    break;

                    // 403 token过期
                    // 登录过期对用户进行提示
                    // 清除本地token和清空vuex中token对象
                    // 跳转登录页面
                case 403:
                    // 清除token
                    localStorage.removeItem("token");
                    // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                    setTimeout(() => {
                        router.replace({
                            path: "/login",
                            query: {
                                redirect: router.currentRoute.fullPath,
                            },
                        });
                    }, 1000);
                    break;

                    // 404请求不存在
                case 404:
                    break;
                    // 其他错误，直接抛出错误提示
                default:
                    // Toast(error.response.data.message);
            }
            return Promise.reject(error.response);
        }
    }
);

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params
            })
            .then((response) => {
                if (response.data && response.data.code > 0) {
                    // Toast(response.data.msg);
                    reject(response);
                } else {
                    resolve(response.data);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.post(url, Qs.stringify(data)).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}
export function postJ(url, data = {}, header = { type: true }) {
    return new Promise((resolve, reject) => {
        axios.post(url, data, header).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function del(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.delete(url, data).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

export default {
    get,
    post,
    postJ,
    patch,
    put,
    del,
    host,
    upload
};