import Vue from "vue";
import VueRouter from "vue-router";
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Serve",
    redirect: '/serve',
    component: (resolve) => void require(["../views/index.vue"], resolve),
    children: [{
            path: '/serve',
            component: (resolve) => void require(["../views/serve/index.vue"], resolve),
            meta: {
                title: "服务中心",
                requireAuth: true,
            },
        },
        {
            path: '/home',
            component: (resolve) => void require(["../views/home/index.vue"], resolve),
            meta: {
                title: "首页",
                requireAuth: true,
            },
        },
        {
            path: '/settled',
            component: (resolve) => void require(["../views/home/settled.vue"], resolve),
            meta: {
                title: "入驻开店",
                requireAuth: true,
            },
        },
        {
            path: '/personShop',
            component: (resolve) => void require(["../views/home/personShop.vue"], resolve),
            meta: {
                title: "个人商家",
                requireAuth: true,
            },
        },
        {
            path: '/enterShop',
            component: (resolve) => void require(["../views/home/enterShop.vue"], resolve),
            meta: {
                title: "企业商家",
                requireAuth: true,
            },
        },
        {
            path: '/login',
            component: (resolve) => void require(["../views/login/index.vue"], resolve),
            meta: {
                title: "登录/注册",
                requireAuth: true,
            },
        },
        {
            path: '/personalLogin',
            component: (resolve) => void require(["../views/login/personalLogin.vue"], resolve),
            meta: {
                title: "个人用户登录",
                requireAuth: true,
            },
        },
        {
            path: '/personRegister',
            component: (resolve) => void require(["../views/login/personRegister.vue"], resolve),
            meta: {
                title: "个人用户注册",
                requireAuth: true,
            },
        },
        {
            path: '/personForgot',
            component: (resolve) => void require(["../views/login/personForgot.vue"], resolve),
            meta: {
                title: "个人用户忘记密码",
                requireAuth: true,
            },
        },
        {
            path: '/enterpriseLogin',
            component: (resolve) => void require(["../views/login/enterpriseLogin.vue"], resolve),
            meta: {
                title: "企业用户登录",
                requireAuth: true,
            },
        },
        {
            path: '/enterRegister',
            component: (resolve) => void require(["../views/login/enterRegister.vue"], resolve),
            meta: {
                title: "企业用户注册",
                requireAuth: true,
            },
        },
        {
            path: '/enterForgot',
            component: (resolve) => void require(["../views/login/enterForgot.vue"], resolve),
            meta: {
                title: "企业用户忘记密码",
                requireAuth: true,
            },
        },
        {
            path: '/privacy',
            component: (resolve) => void require(["../views/login/privacy.vue"], resolve),
            meta: {
                title: "隐私条款",
                requireAuth: true,
            },
        },
        {
            path: '/search',
            component: (resolve) => void require(["../views/home/search.vue"], resolve),
            meta: {
                title: "搜索",
                requireAuth: true,
            },
        },

        {
            path: '/content',
            component: (resolve) => void require(["../views/home/content.vue"], resolve),
            meta: {
                title: "富文本展示",
                requireAuth: true,
            },
        },
        {
            path: '/demand',
            component: (resolve) => void require(["../views/serve/demand.vue"], resolve),
            meta: {
                title: "用户信息",
                requireAuth: true,
            },
        },
        {
            path: '/company',
            component: (resolve) => void require(["../views/company/index.vue"], resolve),
            meta: {
                title: "公司信息",
                requireAuth: true,
            },
        },
        {
            path: '/user',
            component: (resolve) => void require(["../views/serve/user.vue"], resolve),
            meta: {
                title: "查看需求",
                requireAuth: true,
            },
        },
        {
            path: '/shop',
            component: (resolve) => void require(["../views/serve/shop.vue"], resolve),
            meta: {
                title: "店铺",
                requireAuth: true,
            },
        },
        {
            path: '/shopDetail',
            component: (resolve) => void require(["../views/serve/shopDetail.vue"], resolve),
            meta: {
                title: "店铺",
                requireAuth: true,
            },
        },
        {
            path: '/square',
            component: (resolve) => void require(["../views/square/index.vue"], resolve),
            redirect: '/squareIndex',
            children: [{
                    path: '/squareIndex',
                    component: (resolve) => void require(["../views/square/squareIndex.vue"], resolve),
                    meta: {
                        title: "数据广场",
                        requireAuth: true,
                    },
                },
                {
                    path: '/historyQuery',
                    component: (resolve) => void require(["../views/square/historyQuery.vue"], resolve),
                    meta: {
                        title: "数据广场-历史记录",
                        requireAuth: true,
                    },
                },
                {
                    path: '/historyInfo',
                    component: (resolve) => void require(["../views/square/historyInfo.vue"], resolve),
                    meta: {
                        title: "数据广场-历史记录",
                        requireAuth: true,
                    },
                },
                {
                    path: '/advancedQuery',
                    component: (resolve) => void require(["../views/square/advancedQuery.vue"], resolve),
                    meta: {
                        title: "数据广场-高级查询",
                        requireAuth: true,
                    },
                },
                {
                    path: '/searchResult',
                    component: (resolve) => void require(["../views/square/searchResult.vue"], resolve),
                    meta: {
                        title: "数据广场-查询结果",
                        requireAuth: true,
                    },
                },
                {
                    path: '/information',
                    component: (resolve) => void require(["../views/square/information.vue"], resolve),
                    meta: {
                        title: "数据广场-资讯",
                        requireAuth: true,
                    },
                },
                {
                    path: '/blurry',
                    component: (resolve) => void require(["../views/square/blurrySearch.vue"], resolve),
                    meta: {
                        title: "数据广场-模糊查询",
                        requireAuth: true,
                    },
                },
                {
                    path: '/inquiryCompanyHome',
                    component: (resolve) => void require(["../views/square/inquiryCompanyHome.vue"], resolve),
                    meta: {
                        title: "数据广场-公司首页",
                        requireAuth: true,
                    },
                }
            ],
            meta: {
                title: "数据广场",
                requireAuth: true,
            },
        },
        {
            path: '/community',
            component: (resolve) => void require(["../views/community/index.vue"], resolve),
            meta: {
                title: "我要发帖",
                requireAuth: true,
            },
        },
        {
            path: '/communityDetail',
            component: (resolve) => void require(["../views/community/detail.vue"], resolve),
            meta: {
                title: "我要发帖-详情",
                requireAuth: true,
            },
        },
        {
            path: '/manage',
            component: (resolve) => void require(["../views/manage/index.vue"], resolve),
            meta: {
                title: "用户管理",
                requireAuth: true,
            },
        },
        {
            path: '/TAdemand',
            component: (resolve) => void require(["../views/community/TAdemand.vue"], resolve),
            meta: {
                title: "TA的需求",
                requireAuth: true,
            },
        },
        {
            path: '/understand',
            component: (resolve) => void require(["../views/community/understand.vue"], resolve),
            meta: {
                title: "了解企胖胖",
                requireAuth: true,
            },
        },
        {
            path: '/himCreate',
            component: (resolve) => void require(["../views/community/himCreate.vue"], resolve),
            meta: {
                title: "TA的帖子",
                requireAuth: true,
            },
        },
        {
            path: '/post',
            component: (resolve) => void require(["../views/community/post.vue"], resolve),
            meta: {
                title: "我要发帖",
                requireAuth: true,
            },
        },
        {
            path: '/postSuccess',
            component: (resolve) => void require(["../views/community/postSuccess.vue"], resolve),
            meta: {
                title: "发帖成功",
                requireAuth: true,
            },
        },
        {
            path: '/forumSearch',
            component: (resolve) => void require(["../views/community/forumSearch.vue"], resolve),
            meta: {
                title: "搜索帖子",
                requireAuth: true,
            },
        },
        {
            path: '/setInfo',
            component: (resolve) => void require(["../views/manage/index.vue"], resolve),
            children: [{
                    path: '/setInfo',
                    component: (resolve) => void require(["../views/manage/setInfo.vue"], resolve),
                    meta: {
                        title: "基础设置-用户管理",
                        requireAuth: true,
                    },
                },
                {
                    path: '/personOrder',
                    component: (resolve) => void require(["../views/manage/personOrder.vue"], resolve),
                    meta: {
                        title: "我的订单-我发布的-用户管理",
                        requireAuth: true,
                    },
                },
                {
                    path: '/wallet',
                    component: (resolve) => void require(["../views/manage/wallet.vue"], resolve),
                    meta: {
                        title: "我的钱包-用户管理",
                        requireAuth: true,
                    },
                },
                {
                    path: '/myShop',
                    component: (resolve) => void require(["../views/manage/myShop.vue"], resolve),
                    meta: {
                        title: "店铺管理-我的店铺-用户管理",
                        requireAuth: true,
                    },
                },
                {
                    path: '/myShopDetail',
                    component: (resolve) => void require(["../views/manage/myShopDetail.vue"], resolve),
                    meta: {
                        title: "店铺详情-我的店铺-用户管理",
                        requireAuth: true,
                    },
                },
                {
                    path: '/orderDetail',
                    component: (resolve) => void require(["../views/manage/orderDetail.vue"], resolve),
                    meta: {
                        title: "订单详情-我的店铺-用户管理",
                        requireAuth: true,
                    },
                },
                {
                    path: '/editInfo',
                    component: (resolve) => void require(["../views/manage/editInfo.vue"], resolve),
                    meta: {
                        title: "信息编辑-用户管理",
                        requireAuth: true,
                    },
                },
                {
                    path: '/myCreation',
                    component: (resolve) => void require(["../views/manage/myCreation.vue"], resolve),
                    meta: {
                        title: "我的基础资料-我的创作中心-用户管理",
                        requireAuth: true,
                    },
                },
                {
                    path: '/invitation',
                    component: (resolve) => void require(["../views/manage/invitation.vue"], resolve),
                    meta: {
                        title: "发帖详情-我的创作中心-用户管理",
                        requireAuth: true,
                    },
                },
                {
                    path: '/myBuy',
                    component: (resolve) => void require(["../views/manage/myBuy.vue"], resolve),
                    meta: {
                        title: "我的购买服务-我的订单-用户管理",
                        requireAuth: true,
                    },
                },
                {
                    path: '/myOrder',
                    component: (resolve) => void require(["../views/manage/myOrder.vue"], resolve),
                    meta: {
                        title: "我的接单-我的订单-用户管理",
                        requireAuth: true,
                    },
                },

            ],
            meta: {
                title: "用户管理",
                requireAuth: true,
            },
        },
    ]
}, ];

const router = new VueRouter({
    // mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;